import { LitElement, html, nothing } from 'lit'
import { msg, updateWhenLocaleChanges } from '@lit/localize'
import { setLocale } from '#js/components/lit-i18n'

export class ParticipantForm extends LitElement {
  static properties = {
    participants: { type: Array },
    profiles: { type: Array },
    minAgeLimit: { type: Number },
    maxAgeLimit: { type: Number },
    participantLimit: { type: Number },
    for: { type: String }
  }

  form = undefined

  constructor () {
    super()
    this.participants = []
    setLocale(globalThis.language)
    updateWhenLocaleChanges(this)
  }

  connectedCallback () {
    super.connectedCallback()
    this.form = document.getElementById(this.for)
  }

  createRenderRoot () {
    return this
  }

  render () {
    return html`
      <fieldset>
        <legend>
          ${msg('Who participates?')}
        </legend>
        ${this.participants.map(
          (participant, index) => this.getParticipant(participant, index))
        }
        ${this.getUnselectedProfiles()}
        ${this.getFormsetFields()}
      </fieldset>
    `
  }

  getParticipant (participant, index) {
    return html`
      <div class="form__row">
        <p>
          <input type="text"
                 id="id_participant_set-${index}-name"
                 name="participant_set-${index}-name"
                 @input="${(event) => {
                   participant.name = event.target.value
                   this.requestUpdate()
                 }}"
                 .value="${participant.name}"
                 maxlength="30"
                 placeholder=" "
                 ?required="${participant.saveAsProfile || !participant.id}"
          />
          <label for="id_participant_set-${index}-name">${msg('profile name')}</label>
        </p>
        <p>
          <input type="number"
                 min="${this.minAgeLimit}"
                 max="${this.maxAgeLimit}"
                 name="participant_set-${index}-age"
                 id="id_participant_set_${index}-age"
                 .value="${participant.age}"
                 ?required="${this.minAgeLimit || this.maxAgeLimit}"
                 placeholder=" ">
          <label for="id_participant_set_${this.index}-age">${msg('age')}</label>
        </p>
        <div style="flex: 0 1 auto;">
          <ui-button style="line-height: 3.5em;"
                     @click="${() => this.removeParticipant(index)}">
            ${msg('remove')}
          </ui-button>
        </div>
      </div>
      ${this.getSaveAsProfileCheckbox(participant, index)}
      ${this.getProfileNameExistsError(participant)}
    `
  }

  getSaveAsProfileCheckbox (participant, index) {
    if (!participant.id) {
      return html`
        <p>
          <input type="checkbox"
                 id="id_participant_set-${index}-save_as_profile"
                 name="participant_set-${index}-save_as_profile"
                 .checked="${participant.saveAsProfile}"
          />
          <label for="id_participant_set-${index}-save_as_profile">
            ${msg('save as profile')}
          </label>
        </p>
      `
    } else {
      return html`
        <input type="hidden"
               name="participant_set-${index}-save_as_profile"
               value="on"
               id="id_participant_set-${index}-save_as_profile"/>
      `
    }
  }

  getProfileNameExistsError (participant) {
    if (
      !participant.id &&
      (this.profiles.some(profile => profile.name === participant.name) ||
        this.participants.filter(p => p.name === participant.name).length > 1)
    ) {
      participant.error = true
      this.form.setParticipants(this.participants)
      return html`
        <ul class="errorlist">
          <li>${msg('A profile with this name already exists.')}</li>
        </ul>
      `
    }
    participant.error = false
    this.form.setParticipants(this.participants)
  }

  getUnselectedProfiles () {
    return html`
      <p>
        <small class="bubble__wrapper">
          ${this.profiles.map(profile => {
            if (!this.participants.some(participant => participant.id === profile.id)) {
              return html`
                <button type="button"
                        class="bubble bubble--tonal"
                        style="width: auto"
                        @click="${() => this.addParticipant(profile)}">
                  ${profile.name}
                </button>
              `
            }
            return nothing
          })}
          ${this.getAddNewParticipant()}
        </small>
      </p>
    `
  }

  getAddNewParticipant () {
    if (this.participants.length < this.participantLimit) {
      return html`
        <button type="button" class="bubble bubble--tonal"
                @click="${() => this.addParticipant()}">
          ${this.participants.length === 0 ? msg('add participant') : msg('add another person')}
        </button>
      `
    }
  }

  getFormsetFields () {
    return html`
      <input type="hidden"
             name="participant_set-TOTAL_FORMS"
             value="${this.participants.length}"
             id="id_participant_set-TOTAL_FORMS"/>
      <input type="hidden"
             name="participant_set-INITIAL_FORMS"
             value="0"
             id="id_participant_set-INITIAL_FORMS"/>
      <input type="hidden"
             name="participant_set-MIN_NUM_FORMS"
             value="1"
             id="id_participant_set-MIN_NUM_FORMS"/>
      <input type="hidden"
             name="participant_set-MAX_NUM_FORMS"
             value="20"
             id="id_participant_set-MAX_NUM_FORMS"/>
    `
  }

  addParticipant (participant) {
    if (this.participants.length < this.participantLimit) {
      if (participant) {
        this.participants = [...this.participants, {
          ...participant
        }]
      } else {
        this.participants = [...this.participants, {
          id: '',
          age: '',
          name: '',
          saveAsProfile: true,
          error: false
        }]
      }
      this.form.setParticipants(this.participants)
    }
  }

  removeParticipant (index) {
    this.participants.splice(index, 1)
    this.participants = [...this.participants]
    this.form.setParticipants(this.participants)
  }
}

customElements.define('participant-form-lit', ParticipantForm)

export class BookingForm extends LitElement {
  static properties = {
    participantCount: { type: Number, attribute: false },
    submitButtonId: { type: String }
  }

  constructor () {
    super()
    this.participantCount = 0
    setLocale(globalThis.language)
    updateWhenLocaleChanges(this)
  }

  connectedCallback () {
    super.connectedCallback()
    this.submitButton = document.getElementById(this.submitButtonId)
  }

  render () {
    return html`
      <slot name="participant-form"></slot>
      ${this.getDetailsSlot()}
      ${this.getAtLeastOneParticipantWarning()}
      <slot name="form-actions"></slot>
    `
  }

  getAtLeastOneParticipantWarning () {
    if (this.participantCount === 0) {
      return html`
        <p style="text-align: center; color: var(--warning)">
          ${msg('Please add at least one participant.')}
        </p>`
    } else if (this.participantCount === 1) {
      return html`
        <p style="text-align: center">
          ${msg('Did you add all participants?')}
        </p>
      `
    }
    return nothing
  }

  getDetailsSlot () {
    if (this.participantCount > 0) {
      return html`<slot name="details"></slot>`
    }
    return nothing
  }

  setParticipants (participants) {
    this.submitButton.disabled = (
      participants.some(participant => participant.error) ||
      participants.length === 0
    )
    this.participantCount = participants.length
  }
}

customElements.define('booking-form', BookingForm)
